<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="1">
        <v-btn color="warning" @click="goToLavorazioni">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="11" class="text-h5 font-weight-regular"> Lavorazione {{ id }} </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-btn
          class="text-h5 teal lighten-5"
          x-large
          block
          v-if="ruoloPianificatore && statoCreata"
          @click="doSetStatoPronta(true)"
          title="Imposta pronta ad essere lavorata dal gruista"
        >
          Pronta
        </v-btn>
        <v-btn
          color="success"
          class="text-h5"
          x-large
          block
          v-if="ruoloPianificatore && statoPronta"
          @click="doSetStatoPronta(false)"
          title="Annulla stato pronta ad essere lavorata dal gruista"
        >
          Pronta
        </v-btn>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">Istruzioni Operative</v-expansion-panel-header>
            <v-expansion-panel-content class="text-h6 font-weight-regular py-2">
              <div v-for="item in lavorazione.istruzioniOperative" :key="item.viaggioCodice">
                <v-card class="my-2" max-width="400">
                  <v-card-text>
                    <b v-if="ruoloPianificatore">{{ item.codiceEsterno }}</b>
                    <b v-if="!ruoloPianificatore">{{ item.viaggioCodice }}</b>
                    <pre v-if="!ruoloPianificatore">{{ item.istruzioniOperative }}</pre>
                    <v-text-field v-if="ruoloPianificatore" v-model="item.istruzioniOperative" label="Istruzioni Operative"></v-text-field>
                    <v-btn v-if="ruoloPianificatore" block color="primary" @click="updateIstruzioniOperative(item)" title="Salva">
                      <v-icon>mdi-content-save-edit</v-icon>
                    </v-btn>
                  </v-card-text>
                </v-card>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row v-if="ruoloPianificatore" align="center" justify="center">
      <v-col cols="2">
        <v-select v-model="selectedIso" :items="mapIso" label="ISO"></v-select>
      </v-col>
      <v-col cols="2">
        <v-btn large :color="getColorIsoPriorityBtn()" @click="setPriority('iso', selectedIso)" :disabled="selectedIso == null" title="Imposta priorita per ISO Selezionato">
          <v-icon large>mdi-priority-high</v-icon>
        </v-btn>
        <v-btn
          large
          class="mx-2"
          color="error"
          @click="
            selectedIso = null;
            setPriority('iso', null);
          "
          :disabled="selectedIso == null"
          title="Annulla priorita per ISO Selezionato"
        >
          <v-icon large>mdi-close</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="2">
        <v-select v-model="selectedCarrier" :items="mapCarrier" label="Carrier out"></v-select>
      </v-col>
      <v-col cols="2">
        <v-btn
          large
          :color="getColorCarrierOutPriorityBtn()"
          @click="setPriority('carrierOut', selectedCarrier)"
          :disabled="selectedCarrier == null"
          title="Imposta priorita per Carrier Out Selezionato"
        >
          <v-icon large>mdi-priority-high</v-icon>
        </v-btn>
        <v-btn
          large
          class="mx-2"
          color="error"
          @click="
            selectedCarrier = null;
            setPriority('carrierOut', null);
          "
          :disabled="selectedCarrier == null"
          title="Annulla priorita per Carrier Out Selezionato"
        >
          <v-icon large>mdi-close</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="2">
        <v-select v-model="selectedArea" :items="mapArea" label="Area"></v-select>
      </v-col>
      <v-col cols="2">
        <v-btn large :color="getColorAreaPriorityBtn()" @click="setPriority('area', selectedArea)" :disabled="selectedArea == null" title="Imposta priorita per area Selezionato">
          <v-icon large>mdi-priority-high</v-icon>
        </v-btn>
        <v-btn
          large
          class="mx-2"
          color="error"
          @click="
            selectedArea = null;
            setPriority('area', null);
          "
          :disabled="selectedArea == null"
          title="Annulla priorita per area Selezionato"
        >
          <v-icon large>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="12" class="pb-0 mb-0">
        <v-toolbar flat color="panelheader" class="pb-0 mb-0">
          <v-toolbar-title class="black--text text-h5 text-left text-uppercase font-weight-black">
            {{ labelOrientamentoTop }}
            <v-btn x-large color="primary" class="py-2 my-2" @click="doChangeOrientation" title="Cambia orientamento">
              <v-icon x-large>mdi-swap-vertical-bold</v-icon>
            </v-btn>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn medium color="primary" @click="retrieveData()" class="pa-2 ma-2">
            <v-icon title="Aggiorna i dati">mdi-autorenew</v-icon>
          </v-btn>

          <v-btn medium @click="scaricatiFilter = !scaricatiFilter" color="primary" class="pa-2 ma-2">
            <v-icon v-if="scaricatiFilter" title="Visualizza i caricati">mdi-eye-outline</v-icon>
            <v-icon v-else title="Visualizza i scaricati">mdi-eye-off-outline</v-icon>
          </v-btn>

          <div class="text-h6 text-left font-weight-bold pa-2 ma-2">SCARICATI</div>

          <div class="text-h4 text-left font-weight-black pa-2 ma-2">{{ cntLavorati }}/{{ cntTotali }}</div>
        </v-toolbar>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-data-table :headers="headersByRole" :items="lavorazione.containers" item-key="n" :show-select="false" :disable-pagination="true" :hide-default-footer="true">
          <template v-slot:footer>
            <v-footer class="black--text text-h5 text-left text-uppercase font-weight-black panelheader">
              {{ labelOrientamentoBottom }}
            </v-footer>
          </template>

          <template v-slot:body="props">
            <draggable
              :list="props.items"
              tag="tbody"
              :handle="draggableListEnabled === 'true' && ruoloPianificatore ? '.handle' : null"
              :disabled="draggableListEnabled === 'true' && ruoloPianificatore ? false : true"
            >
              <tr v-for="(ctr, index) in props.items" :key="index" :class="ctr.scaricato ? 'scaricato' : ''" :style="!scaricatiFilter && ctr.scaricato ? 'display:none' : ''">
                <td
                  v-if="draggableListEnabled === 'true' && ruoloPianificatore"
                  class="text-center"
                  :class="draggableListEnabled === 'true' && ruoloPianificatore ? 'handle text-center' : 'text-center'"
                >
                  <v-icon v-if="draggableListEnabled === 'true' && ruoloPianificatore" class="mt-0" x-large>mdi-drag-horizontal</v-icon>
                </td>
                <!-- <td class="text-body-1 text-left font-weight-regular">{{ctr.n}}</td> -->
                <td class="text-h6 text-left font-weight-bold">
                  {{ !lavorazione.orientation ? ctr.posizioneConvoglio : ctr.numeroCarriConvoglio - ctr.posizioneConvoglio + 1 }}
                </td>
                <!-- <td class="text-h6 text-left font-weight-regular">
                  {{ ctr.targaCarro }}
                </td> -->
                <td class="text-h5 text-left font-weight-black">
                  {{ ctr.containerCode }}
                </td>
                <td class="text-body-1 text-left font-weight-regular">
                  {{ ctr.iso }}
                </td>
                <td class="text-body-1 text-left font-weight-regular">
                  {{ ctr.lunghezzaPiedi }}
                </td>
                <td class="text-body-1 text-left font-weight-regular">
                  {{ ctr.carrierOut }}
                </td>
                <td class="text-body-1 text-left font-weight-regular">
                  {{ ctr.peso }}
                </td>
                <td class="text-body-1 text-left font-weight-regular">
                  {{ ctr.imo }}
                </td>
                <td class="text-body-1 text-left font-weight-regular">
                  {{ ctr.area }}
                </td>
                <td class="text-body-1 text-left font-weight-regular">
                  {{ ctr.note }}
                </td>
                <td class="text-body-1 text-left font-weight-regular">
                  {{ ctr.binarioNumero }}
                </td>
              </tr>
            </draggable>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>



<script>
import ToastMixin from "../../mixins/ToastMixin";
import Draggable from "vuedraggable";
import RailsMixins from "../../components/rails/mixins/railsMixins";

export default {
  data() {
    return {
      draggableListEnabled:
        process.env.VUE_APP_CRANE_DRAGGABLE_LIST_ENABLED || "false",
      scaricatiFilter: false,
      lavorazione: {},
      mapIso: [],
      mapCarrier: [],
      mapArea: [],
      selectedArea: null,
      selectedIso: null,
      selectedCarrier: null,
      cntLavorati: 0,
      cntTotali: 0,
      dialogModify: false,
      timerLavora: null,
      headers: [
        {
          text: "",
          value: "draggable",
          class:
            "text-body-1 text-left text-uppercase font-weight-regular header",
          sortable: false,
        },
        // { text: 'N', value:'n', class:'text-body-1 text-center text-uppercase font-weight-regular header', sortable: false },
        {
          text: "Pos.",
          value: "posizioneConvoglio",
          class:
            "text-body-1 text-left text-uppercase font-weight-regular header",
          sortable: false,
        },
        // {
        //   text: "Carro",
        //   value: "targaCarro",
        //   class:
        //     "text-body-1 text-left text-uppercase font-weight-regular header",
        //   sortable: false,
        // },
        {
          text: "CNT CODE",
          value: "containerCode",
          class:
            "text-body-1 text-left text-uppercase font-weight-regular header",
          sortable: false,
        },
        {
          text: "ISO",
          value: "iso",
          class:
            "text-body-1 text-left text-uppercase font-weight-regular header",
          sortable: false,
        },
        {
          text: "LUNGH. (ft)",
          value: "iso",
          class: "text-body-1 text-left font-weight-regular header",
          sortable: false,
        },
        {
          text: "Carrier",
          value: "carrierOut",
          class:
            "text-body-1 text-left text-uppercase font-weight-regular header",
          sortable: false,
        },
        {
          text: "Peso",
          value: "peso",
          class:
            "text-body-1 text-left text-uppercase font-weight-regular header",
          sortable: false,
        },
        {
          text: "IMO",
          value: "imo",
          class:
            "text-body-1 text-left text-uppercase font-weight-regular header",
          sortable: false,
        },
        {
          text: "Area",
          value: "area",
          class:
            "text-body-1 text-left text-uppercase font-weight-regular header",
          sortable: false,
        },
        {
          text: "Note",
          value: "note",
          class:
            "text-body-1 text-left text-uppercase font-weight-regular header",
          sortable: false,
        },
        {
          text: "Bin.",
          value: "binarioNumero",
          class:
            "text-body-1 text-left text-uppercase font-weight-regular header",
          sortable: false,
        },
      ],
    };
  },
  props: ["id"],
  components: {
    Draggable,
  },
  watch: {},
  mixins: [ToastMixin,RailsMixins],
  async created() {
    try{
      if (!this.hasCosmosConnector()) {
       setInterval(async () => {
         try{
            this.retrieveData();
          } catch(e) {
            console.log(e);
          }
        }, 60000);
      }

    if (this.hasCosmosConnector()) { 
       this.timerLavora = setInterval(async () => {
         try{
            if (this.lavorazione && this.lavorazione.istruzioniOperative && this.lavorazione.istruzioniOperative.length > 0) {              
                   this.lavorazione.istruzioniOperative.forEach(io => {
                    this.doRequestCosmosExportHandlingInExe(io.codiceEsterno);
                });
               await new Promise(r => setTimeout(r, 10000));
               this.retrieveData();
            }   
          } catch(e) {
            console.log(e);
          }
        }, 30000);
      }
      
        console.log("await register scaricoUti...");
        await this.$wsHubRegister("scaricoUti", {}, (data) =>
          this.onProcessScaricoUtiMessage(data)
        );
        console.log("await register scaricoUti done");

      console.log("await register annullaScaricoUti...");
      await this.$wsHubRegister("annullaScaricoUti", {}, (data) =>
        this.onProcessAnnullaScaricoUtiMessage(data)
      );
      console.log("await register annullaScaricoUti done");

    } catch (e) {
        console.log("ERRORE registrazione", e);
    }
  },
  async destroyed() {
    try {
      console.log("await unregister scaricoUti...");
      await this.$wsHubUnRegister("scaricoUti");
      console.log("await unregister scaricoUti done");

      console.log("await unregister annullaScaricoUti...");
      await this.$wsHubUnRegister("annullaScaricoUti");
      console.log("await unregister annullaScaricoUti done");
      console.log("await unregister Interval...");

      if(this.timerLavora){
          clearInterval(this.timerLavora);
      }  

    } catch (e) {
      console.log("ERRORE de-registrazione", e);
    }
  },
  mounted() {
    this.retrieveData();
  },
  computed: {
    labelOrientamento() {
      return !this.lavorazione.orientation
        ? this.lavorazione.direzionePosizioneInizialeParco
        : this.lavorazione.direzionePosizioneFinaleParco;
    },
    labelOrientamentoTop() {
      return !this.lavorazione.orientation
        ? this.lavorazione.direzionePosizioneInizialeParco
        : this.lavorazione.direzionePosizioneFinaleParco;
    },
    labelOrientamentoBottom() {
      return this.lavorazione.orientation
        ? this.lavorazione.direzionePosizioneInizialeParco
        : this.lavorazione.direzionePosizioneFinaleParco;
    },
    ruoloPianificatore() {
      const roles = this.$store.getters["authentication/loggedUser"].roles;
      if (roles) {
        return roles.includes("ADMINISTRATOR");
      }
      return false;
    },

    statoCreata() {
      return this.lavorazione.stato === "CREATA";
    },
    statoPronta() {
      return this.lavorazione.stato === "PRONTA";
    },
    headersByRole() {
      if (this.draggableListEnabled === "true" && this.ruoloPianificatore) {
        return this.headers;
      } else {
        let c = this.headers.filter((item) => item.value !== "draggable");
        return c;
      }
    },
  },
  methods: {
    async retrieveData() {
      let cntLavorati = 0;
      let cntTotali = 0;
      this.mapCarrier=[];
      this.mapIso=[];
      this.mapArea=[];

      this.lavorazione = await this.$api.trainData.getDatiLavorazione(this.id);
      console.log("Dati lavorazione", this.lavorazione);
      this.lavorazione.containers.forEach((cnt) => {
        if (cnt.iso) this.mapIso[cnt.iso] = true;
        if (cnt.carrierOut) this.mapCarrier[cnt.carrierOut] = true;
        if (cnt.area) this.mapArea[cnt.area] = true;
        //this.cntTotali++;
        cntTotali++;
        if (cnt.scaricato) cntLavorati++;//this.cntLavorati++;
      });
      this.mapIso = Object.keys(this.mapIso);
      this.mapCarrier = Object.keys(this.mapCarrier);
      this.mapArea = Object.keys(this.mapArea);
      this.cntLavorati = cntLavorati;
      this.cntTotali = cntTotali;

    if (this.hasCosmosConnector()) {
       if (this.lavorazione && this.lavorazione.istruzioniOperative && this.lavorazione.istruzioniOperative.length > 0) { 
            this.lavorazione.istruzioniOperative.forEach( (io) => {
              this.doRequestCosmosUnloadingList(io.codiceEsterno);
          });
       }
    }          
    },
    async doRequestCosmosExportHandlingInExe(codiceEsterno) { 
        await this.$api.trainData.doRequestCosmosExportHandlingInExe(codiceEsterno);
    },  
    async doRequestCosmosUnloadingList(codiceEsterno) { 
        await this.$api.trainData.doRequestCosmosUnloadingList(codiceEsterno);
    },     
    async doSetStatoPronta(pronta) {
      console.log("doSetStatoPronta", pronta);
      this.lavorazione = await this.$api.trainData.setLavorazioneStatoPronta(
        this.id,
        pronta
      );
    },
    async setPriority(type, value) {
      console.log("SetPriority", type, value);
      this.lavorazione = await this.$api.trainData.setLavorazionePriority(
        this.id,
        type,
        value
      );
    },
    async doChangeOrientation() {
      console.log("doChangeOrientation");
      this.lavorazione = await this.$api.trainData.changeLavorazioneOrientation(
        this.id
      );
    },

    async updateIstruzioniOperative(item) {
      this.dialogModify = false;
      console.log("idViaggio", item.viaggioId);
      console.log("istuzioniOperativeData", item.istruzioniOperative);
      await this.$api.trainData.saveIstruzioniOperative(
        item.viaggioId,
        item.istruzioniOperative
      );
      return;
    },

    onProcessScaricoUtiMessage(cnt) {
      console.log("Received scarico uti Message", cnt);
      console.log(
        "lavorazione.containers length",
        this.lavorazione.containers.length
      );
      if (cnt == null) {
        console.log("Skip. null");
        return;
      }
      for (let pos in this.lavorazione.containers) {
        const container = this.lavorazione.containers[pos];
        if (container.containerCode == cnt) {
          container.scaricato = true;
          this.cntLavorati++;
          this.lavorazione.containers.push(container);
          console.log(
            "lavorazione.containers length after push",
            this.lavorazione.containers.length
          ),
            container;
          this.lavorazione.containers.splice(pos, 1);
          console.log(
            "lavorazione.containers length after splice",
            this.lavorazione.containers.length,
            pos
          );
          return;
        }
      }
      console.log("Cnt non trovato", cnt);
    },
    onProcessAnnullaScaricoUtiMessage(cnt) {
      console.log("Received annulla scarico uti Message", cnt);
      if (cnt == null) {
        console.log("Skip. null");
        return;
      }
      for (let pos in this.lavorazione.containers) {
        if (this.lavorazione.containers[pos].containerCode == cnt) {
          this.lavorazione.containers[pos].scaricato = false;
          this.cntLavorati--;
          this.arrayMove(
            this.lavorazione.containers,
            pos,
            this.lavorazione.containers[pos].n - 1
          );
          return;
        }
      }
      console.log("Cnt non trovato", cnt);
    },

    arrayMove(arr, oldIndex, newIndex) {
      if (newIndex >= arr.length) {
        var k = newIndex - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    },
    getColorIsoPriorityBtn() {
      return "primary";
    },
    getColorCarrierOutPriorityBtn() {
      return "primary";
    },
    getColorAreaPriorityBtn() {
      return "primary";
    },
    rowMoved(id, event) {
      console.log("rowMoved -------->", id, event);
    },
    goToLavorazioni() {
      this.$router.push({ path: "/lavorazioni" });
    },
  },
};
</script>

<style scoped>
.handle {
  cursor: move;
}
.red {
  color: red;
}
.lineNumber {
  font-size: x-large;
  font-weight: bold;
}
.containerCode {
  font-size: xx-large;
  font-weight: bold;
}
.contatori {
  font-size: x-large;
  font-weight: bold;
  color: red;
}
.scaricato {
  color: lightgray;
}

@media (max-width: 1024px) {
  .p-datatable .p-sortable-column.p {
    border: 2px solid red;
  }
  .p-datatable .p-sortable-column.p-highlight {
    border: 3px solid red;
  }

  .errorMessage {
    color: red;
  }
}
</style>
